
export function setValue(key, value) {
   window.localStorage.setItem(key, JSON.stringify(value));
}

export function getValue(key, defaultValue) {
   const val = window.localStorage.getItem(key);
   if (val === null) return defaultValue;
   else return JSON.parse(val);
}

export function saveGameSelection(gameType, gameSubType, gameFilter, region) {
   const state = { gameType, gameSubType, gameFilter, region };
   setValue("gameSelection", state);
}

export function loadGameSelection() {
   return getValue("gameSelection", {
      gameType: 'guessCountry',
      gameSubType: 'practice',
      gameFilter: 'all',
      region: 'World'
   });
}

export function saveGameScore(gameSelection, score, duration) {
   const key = mkScoreKey(gameSelection);
   const scoreVal = getValue(key, { score: 0, duration: Number.POSITIVE_INFINITY });
   if (score >= scoreVal.score) {
      let newRecord = (score > scoreVal.score);
      scoreVal.score = score;
      if (scoreVal.duration > duration) {
         scoreVal.duration = duration;
         newRecord = true;
      }
      scoreVal.newRecord = newRecord;
      setValue(key, scoreVal);
   }
   return scoreVal;
}

export function loadGameScore(gameSelection) {
   const key = mkScoreKey(gameSelection);
   const scoreVal = getValue(key, { score: 0, duration: Number.POSITIVE_INFINITY });
   return scoreVal;
}

export function mkScoreKey(gameSelection) {
   return `score_${gameSelection.region}_${gameSelection.gameType}_${gameSelection.gameSubType}_${gameSelection.gameFilter}`;
}