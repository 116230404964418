import React, { createContext, useState, useEffect } from 'react';

export const CountriesContext = createContext();

const CountriesContextProvider = (props) => {

   const [countries, setCountries] = useState();
   useEffect(() => {
      fetch('data/_countries.json')
         .then((response) => response.json())
         .then((data) => {
            setCountries(data);
         })
         .catch((err) => console.error(err))
   }, []);

   return (
      <CountriesContext.Provider value={countries}>
         {props.children}
      </CountriesContext.Provider>
   )
}

export default CountriesContextProvider;