import React, { useMemo } from 'react'
import { Stack, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { msToStr } from '../contexts/GameContext';
import { saveGameScore } from '../logic/storage';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

export default function ScoreView(props) {
   const { message, score, duration, onPlayAgain, gameSelection } = props;
   const navigate = useNavigate();
   const bestScore = useMemo(() => saveGameScore(gameSelection, score, duration), [score, duration, gameSelection]);

   return (
      <Stack direction='column' spacing={2} justifyContent='space-between' className='logoImageBackground2' sx={{ height: '100%' }}>
         <Typography variant='h3' align='center' >{message}</Typography>
         <Typography variant='h1' align='center' pt={6}>Your score</Typography>
         <Typography variant='h1' align='center' >{score.toFixed(1)}</Typography>
         <Typography variant='h1' align='center' >{msToStr(duration)}</Typography>
         <div style={{ flexGrow: 1 }} >
            {bestScore.newRecord === true &&
               <Stack direction='row' justifyContent='space-evenly' alignItems='center' width='100%'>
                  <FavoriteBorderIcon />
                  <Typography variant='h1' align='center' >Yay, new best score!</Typography>
                  <FavoriteBorderIcon />
               </Stack>
            }
         </div>
         <Typography variant='h1' align='center' pt={6}>Best score</Typography>
         <Typography variant='h1' align='center'>{bestScore.score.toFixed(1)}</Typography>
         <Typography variant='h1' align='center' pb={4}>{msToStr(bestScore.duration)}</Typography>

         <Button variant='contained' fullWidth onClick={onPlayAgain} >Play again</Button>
         <Button variant='contained' fullWidth onClick={() => navigate('/', { replace: true })} >Return to menu</Button>
      </Stack>
   )
}
