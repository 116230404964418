import React, { useEffect, useContext, useState } from 'react'
import StatusContext from '../contexts/StatusContext';
import { CountriesContext } from '../contexts/CountriesContext';
import { useLocation } from 'react-router-dom';
import { Stack, Typography, Grid, Button } from '@mui/material';
import { GameContext, ACTION_START, ACTION_CHOICE, ACTION_CLEARMISTAKE } from '../contexts/GameContext';
import { loadGameSelection } from '../logic/storage';
import GameStatusBar from './GameStatusBar';
import ScoreView from './ScoreView';

export default function GuessTheFlag() {
   const location = useLocation();

   const { setCurrentTitle } = useContext(StatusContext);
   const [gameSelection] = useState(
      location.state ? location.state : loadGameSelection()
   );

   const countries = useContext(CountriesContext);
   const [gameState, dispatch] = useContext(GameContext);

   useEffect(() => {
      setCurrentTitle({ title: "Guess the flag", backBtnVisible: true });

      if (countries) {
         dispatch({ type: ACTION_START, gameSelection: gameSelection, countries: countries });
      }
      return () => {
      }
   }, [countries, gameSelection, dispatch, setCurrentTitle]);

   const handleCountryBtnClick = (selCountry) => {
      dispatch({ type: ACTION_CHOICE, selCountry: selCountry, onClearError: clearMistakeAndContinue });
   }

   const handlePlayAgainBtn = () => {
      dispatch({ type: ACTION_START, gameSelection: gameSelection, countries: countries });
   }

   const clearMistakeAndContinue = () => {
      dispatch({ type: ACTION_CLEARMISTAKE });
   }

   return (
      <>
         {countries ?
            <>
               {gameState.score === null ?
                  <>
                     <GameStatusBar
                        numAccepted={gameState.numAccepted}
                        numErrors={gameState.numErrors}
                        numTotal={gameState.numTotal}
                        livesTotal={gameState.livesTotal}
                        livesCurrent={gameState.livesCurrent}
                        sx={{ mb: 3 }}
                     />

                     <Stack direction="column" justifyContent="space-between" alignItems='center' sx={{ height: "95%" }}>

                        <Typography variant='h1' pt={2}>{gameState.currentCountry.n}</Typography>

                        <Grid container spacing={1} alignItems="stretch" justifyContent="space-between">
                           {gameState.lastError ?
                              <>
                                 <Grid item xs={12} alignSelf='center' style={{ textAlign: "center" }}>
                                    <Typography variant='h3'> Sorry, {gameState.lastError.n}'s flag is</Typography>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <Button fullWidth disabled >
                                       <img src={`/data/${gameState.lastError.c}.svg`} className="flagImage" alt="flag" />
                                    </Button>
                                 </Grid>
                              </>
                              :
                              <>
                                 {gameState.currentOptions.map((value, index) => {
                                    return (
                                       value &&
                                       <Grid item key={index} xs={6} sm={4}>
                                          <Button fullWidth
                                             disabled={gameState.lastError !== null}
                                             onClick={() => handleCountryBtnClick(value)}
                                             sx={{ height: "100%" }}
                                          >
                                             <img src={`/data/${value.c}.svg`} className="flagImageSmall" alt="flag" />
                                          </Button>
                                       </Grid>
                                    )
                                 })}
                              </>
                           }
                        </Grid>
                     </Stack>
                  </> :
                  <ScoreView message={gameState.endMessage} score={gameState.score} duration={gameState.gameDuration} gameSelection={gameSelection} onPlayAgain={handlePlayAgainBtn} />
               }
            </>
            : <div>Loading ...</div>
         }
      </>
   )
}
