import React from 'react'
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function NavigateBackButton(props) {
   const { visible, color } = props;
   const navigate = useNavigate();
   return (
      <React.Fragment>
         {visible ? <IconButton color={color} onClick={() => navigate('/', { replace: true })}><ChevronLeftIcon /></IconButton> : null}
      </React.Fragment>
   );
}

NavigateBackButton.defaultProps = {
   visible: true,
   color: "inherit"
}

export default NavigateBackButton;