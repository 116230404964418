//inspired from https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array

//doesn't actually work, produces some null entries sometimes :(
export function shuffle(t) {
   return Array.from(sample(t, t.length));
}

function* sample(t, n) {
   let r = Array.from(t)
   while (n > 0 && r.length) {
      const i = rand(r.length) // 1
      swap(r, i, r.length - 1) // 2
      yield r.pop()            // 3
      n = n - 1
   }
}
function swap(t, i, j) {
   let q = t[i]
   t[i] = t[j]
   t[j] = q
   return t
}


//those work well ...

export function rand(n) {
   return Math.round(Math.random() * n);
}

export function getRandom(arr, n) {
   var result = new Array(n),
       len = arr.length,
       taken = new Array(len);
   if (n > len) n = len ;
   while (n--) {
       var x = Math.floor(Math.random() * len);
       result[n] = arr[x in taken ? taken[x] : x];
       taken[x] = --len in taken ? taken[len] : len;
   }
   return result;
}