import React, { useEffect, useState, useContext } from 'react'
import { Box, Typography, Link, Stack } from '@mui/material'
import StatusContext from '../contexts/StatusContext';
import StarIcon from '@mui/icons-material/Star';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { height } from '@mui/system';

export default function AboutView() {
   const [appInfo, setAppInfo] = useState({});
   const { setCurrentTitle } = useContext(StatusContext);

   useEffect(() => {
      const infoObj = {
         name: "World of flags",
         version: "0.1.0",
         buildDate: "July 2022"
      }
      setAppInfo(infoObj);
      setCurrentTitle({ title: "About", backBtnVisible: true });
   }, [setCurrentTitle, setAppInfo]);

   return (
      <Box height='100%' className='logoImageBackground'>
         <Typography variant='h2'>World of flags</Typography>
         <Typography paragraph>© 2022 Pavel Ceselsky | All rights reserved | <Link underline='hover' variant='inherit' color='primary' href="https://ceselsky.cz">Contact</Link> </Typography>

         <Typography variant='h2' mt={4}>App information</Typography>
         <Typography paragraph>
            Version: {appInfo.version} , Build date: {appInfo.buildDate}
         </Typography>
         <Typography variant='h2' mt={4}>Credits</Typography>
         <Typography paragraph component={'span'}>
            <List>
               <ListItem disablePadding>
                  <ListItemIcon>
                     <StarIcon />
                  </ListItemIcon>
                  <ListItemText
                     primary={< Link underline='hover' variant='inherit' color='primary' href="https://github.com/mledoze/countries">github.com/mledoze/countries</Link>}
                     secondary="Big thanks for providing country data and flags" />
               </ListItem>
               <ListItem disablePadding>
                  <ListItemIcon>
                     <StarIcon />
                  </ListItemIcon>
                  <ListItemText
                     primary={< Link underline='hover' variant='inherit' color='primary' href="https://loading.io/icon/f00uly">loading.io/icon/f00uly</Link>}
                     secondary="Globe icon used as site favicon and logo" />
               </ListItem>
            </List>
         </Typography>

      </Box>
   )
}
