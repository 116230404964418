import React from 'react';
import { useEffect, useContext, useState } from 'react';
import { Typography, Box, Button, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import StatusContext from '../contexts/StatusContext';
import { CountriesContext } from '../contexts/CountriesContext';
import { useNavigate } from 'react-router-dom';
import { saveGameSelection, loadGameSelection } from '../logic/storage';
import { ACTION_INIT, GameContext } from '../contexts/GameContext';

export default function MainView() {
   const { setCurrentTitle } = useContext(StatusContext);
   const [gameType, setGameType] = useState('guessCountry');
   const [gameSubType, setGameSubType] = useState('practice');
   const [gameFilter, setGameFilter] = useState('all');

   const countries = useContext(CountriesContext);
   const [, dispatch] = useContext(GameContext);

   const navigate = useNavigate();

   useEffect(() => {
      setCurrentTitle({ title: "World of flags", backBtnVisible: false })
      const gselection = loadGameSelection();
      setGameType(gselection.gameType);
      setGameSubType(gselection.gameSubType);
      setGameFilter(gselection.gameFilter);
      dispatch({ type: ACTION_INIT });
      return () => { }
   }, [setCurrentTitle]);

   const handleGameType = (event, newGameType) => {
      if (newGameType !== null) setGameType(newGameType);
   };
   const handleGameSubType = (event, newGameSubType) => {
      if (newGameSubType !== null) setGameSubType(newGameSubType);
   };
   const handleGameFilter = (event, newGameFilter) => {
      if (newGameFilter !== null) setGameFilter(newGameFilter);
   };

   const startGame = (region) => {
      saveGameSelection(gameType, gameSubType, gameFilter, region);

      switch (gameType) {
         case 'guessCountry':
            navigate('/GuessTheCountry', {
               replace: true,
               state: {
                  region: region,
                  gameType: gameType,
                  gameFilter: gameFilter,
                  gameSubType: gameSubType
               }
            });
            break;
         case 'guessFlag':
            navigate('/GuessTheFlag', {
               replace: true,
               state: {
                  region: region,
                  gameType: gameType,
                  gameFilter: gameFilter,
                  gameSubType: gameSubType
               }
            });
            break;

         default:
            break;
      }
   }

   return (
      <Box sx={{ flexGrow: 1 }}>
         <Stack spacing={1.8} alignItems='center' justifyContent="flex-start">
            <Typography variant='h2'>Game type</Typography>
            <ToggleButtonGroup color='primary' exclusive size='small'
               value={gameType} onChange={handleGameType}>
               <ToggleButton value='guessCountry'>Guess the country</ToggleButton>
               <ToggleButton value='guessFlag'>Guess the flag</ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup color='primary' exclusive size='small'
               value={gameSubType} onChange={handleGameSubType}>
               <ToggleButton value='practice'>Practice</ToggleButton>
               <ToggleButton value='5lives'>5 lives</ToggleButton>
               <ToggleButton value='3lives'>3 lives</ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup color='primary' exclusive size='small'
               value={gameFilter} onChange={handleGameFilter}>
               <ToggleButton value='all'>All countries</ToggleButton>
               <ToggleButton value='independent'>Only independent</ToggleButton>
               <ToggleButton value='un'>Only UN members</ToggleButton>
            </ToggleButtonGroup>
            <Typography variant='h2'>Region</Typography>
            <Button variant='contained'
               onClick={() => startGame('World')}
               color='primary'
               sx={{ minWidth: 250 }}>World</Button>
            {countries ? Object.keys(countries).map((key, keyIndex) =>
               <Button key={keyIndex}
                  onClick={() => startGame(key)}
                  variant='contained'
                  color='primary'
                  sx={{ minWidth: 250 }}>{key}</Button>
            ) : 'Loading...'}
         </Stack>
      </Box>
   )
}
