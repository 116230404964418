import React, { useEffect, useState, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
   ThemeProvider,
   createTheme,
   /*responsiveFontSizes,*/
} from "@mui/material/styles";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Header from "./Header";
import StatusContext from "../contexts/StatusContext";
import ColorModeContext from "../contexts/ColorModeContext";
import CountriesContextProvider from "../contexts/CountriesContext";
import GameContextProvider from "../contexts/GameContext";

export default function Layout() {
   const location = useLocation();

   useEffect(() => {
      //console.log("Current location: ", location.pathname);
   }, [location]);

   const [currentTitle, setCurrentTitle] = useState({
      title: "World of flags",
      backBtnVisible: true,
   });
   const titleValue = useMemo(
      () => ({ currentTitle, setCurrentTitle }),
      [currentTitle]
   );

   const [colorMode, setColorMode] = useState(
      window.localStorage.getItem("colorMode") === "null" ? "light" : window.localStorage.getItem("colorMode")
   );
   const colorModeValue = useMemo(
      () => ({ colorMode, setColorMode }),
      [colorMode]
   );

   const theme = React.useMemo(() => {
      //console.log("using theme: ", colorMode);
      window.localStorage.setItem("colorMode", colorMode);
      return createTheme({
         palette: {
            mode: colorMode,
         },
         typography: {
            h1: {
               fontSize: 32,
            },
            h2: {
               fontSize: 24,
            },
            h3: {
               fontSize: 20,
            },
            h4: {
               fontSize: 18,
            },
            h5: {
               fontSize: 18,
            },
            h6: {
               fontSize: 16,
            },
         },
         breakpoints: {
            values: {
               xs: 0,
               sm: 500,
               md: 700,
               lg: 1000,
               xl: 1200,
            },
         }
      });
   }, [colorMode]);

   return (
      <ColorModeContext.Provider value={colorModeValue}>
         <ThemeProvider theme={theme}>
            <StatusContext.Provider value={titleValue}>
               <CssBaseline />
               <Box sx={{ height: '100vh', display: 'flex', flexDirection: "column" }}>
                  <AppBar
                     position="static"
                     sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  >
                     <Header />
                  </AppBar>
                  <GameContextProvider>
                     <Container fixed sx={{ flexGrow: 1 }}>
                        <Paper elevation={3} sx={{ p: 1, pb: 4, m: 0.5, mt: 0.3, height: "99%" }}>
                           <CountriesContextProvider>
                              <Outlet />
                           </CountriesContextProvider>
                        </Paper>
                     </Container>
                  </GameContextProvider>
               </Box>
            </StatusContext.Provider>
         </ThemeProvider>
      </ColorModeContext.Provider>
   );
}
