import React, { useEffect, useContext } from 'react'
import StatusContext from '../contexts/StatusContext';
import ColorModeContext from '../contexts/ColorModeContext';
import { Switch, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';

export default function SettingsView() {
   const { setCurrentTitle } = useContext(StatusContext);
   const { colorMode, setColorMode } = useContext(ColorModeContext);


   useEffect(() => {
      setCurrentTitle({ title: "Settings", backBtnVisible: true });
   }, [setCurrentTitle]);

   const handleChange = (event) => {
      setColorMode(event.target.checked ? 'dark' : 'light');
   };

   return (
      <div>
         <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Appearance settings</FormLabel>
            <FormGroup>
               <FormControlLabel
                  control={
                     <Switch
                        checked={colorMode === 'light' ? false : true}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        name='colorSwitch'
                     />
                  }
                  label="Dark color mode"
               />

            </FormGroup>
         </FormControl>
      </div>
   )
}
