import React from 'react'
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import StatusContext from '../contexts/StatusContext';

export default function NoMatch() {
   const { setCurrentTitle } = useContext(StatusContext);
   const location = useLocation();

   useEffect(() => {
      setCurrentTitle({ title: "Page not found", backBtnVisible: true })
      return () => {
      }
   }, [setCurrentTitle]);

   return (
      <div>Address not found: {location.pathname}</div>
   )
}
