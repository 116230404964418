import React from 'react'
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

function Stars(props) {
   const { total, current } = props;
   const arr1 = new Array(total).fill();

   return (
      <Stack direction='row' {...props}>
         {
            arr1.map((_, index) => {
               return (
                  (index < current) ? <StarIcon key={index} color='primary' />
                     : <StarBorderIcon key={index} color='primary'/>
               )
            })
         }
      </Stack>
   )
}

Stars.propTypes = {
   total: PropTypes.number.isRequired,
   current: PropTypes.number.isRequired
};

export default Stars;