import React from 'react'
import { useReducer, createContext } from 'react';
import { rand, getRandom } from '../logic/shuffle';
import { calcScore } from '../logic/score';

export const GameContext = createContext();

export const ACTION_INIT = "INITIALIZE";
export const ACTION_START = "START";
export const ACTION_GENNEXT = "GENNEXT";
export const ACTION_CHOICE = "CHOICE";
export const ACTION_CLEARMISTAKE = "CLEARMISTAKE";

const initialState = {
   numTotal: 0,
   numAccepted: 0,
   numErrors: 0,
   currentCountry: {},
   currentOptions: [],
   remainingCountries: [],
   lastError: null,
   score: null,
   livesTotal: null,
   livesCurrent: 0,
   endMessage: 'Something to show on score screen',
   startTime: new Date(),
   gameDuration: null
};

function genNext(state) {
   const newState = { ...state };
   newState.currentCountry = newState.remainingCountries[rand(newState.remainingCountries.length - 1)];
   newState.currentOptions = getRandom(newState.remainingCountries, 6);
   if (!newState.currentOptions.includes(newState.currentCountry)) {
      const cIdx = rand(5);
      newState.currentOptions[cIdx] = newState.currentCountry;
   }
   return newState;
}

function initGame(gameSelection, countries) {
   let countryList = [];
   if (countries) {
      if (gameSelection.region === 'World') {
         for (const [, value] of Object.entries(countries)) {
            for (const c of value) {
               countryList.push(c);
            }
         }
      } else {
         countries[gameSelection.region].forEach(el => countryList.push(el));
      }
   }
   if (gameSelection.gameFilter === 'independent') {
      const filtered = countryList.filter(c => c.i);
      if (filtered.length > 0) countryList = filtered;
   } else if (gameSelection.gameFilter === 'un') {
      const filtered = countryList.filter(c => c.u);
      if (filtered.length > 0) countryList = filtered;
   }
   let livesTotal = null;
   let livesCurrent = 0;
   if (gameSelection.gameSubType === '5lives') {
      livesTotal = 5;
      livesCurrent = 5;
   } else if (gameSelection.gameSubType === '3lives') {
      livesTotal = 3;
      livesCurrent = 3;
   }
   const initState = {
      numTotal: countryList.length,
      numAccepted: 0,
      numErrors: 0,
      currentCountry: {},
      currentOptions: [],
      remainingCountries: [...countryList],
      lastError: null,
      score: null,
      livesTotal: livesTotal,
      livesCurrent: livesCurrent,
      endMessage: 'Congratulations, you finished the game!',
      startTime: new Date(),
      gameDuration: null
   }
   return initState;
}

function secondsToTime(secs) {
   secs = Math.round(secs);
   const hours = Math.floor(secs / (60 * 60));
   const divisor_for_minutes = secs % (60 * 60);
   const minutes = Math.floor(divisor_for_minutes / 60);
   const divisor_for_seconds = divisor_for_minutes % 60;
   const seconds = Math.ceil(divisor_for_seconds);
   const obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
   };
   return obj;
}

export function msToStr(msecs) {
   const tobj = secondsToTime(msecs / 1000);
   return `${(tobj.h * 60 + tobj.m)}m ${tobj.s}s`;
}

const gameReducer = (state, action) => {
   switch (action.type) {
      case ACTION_INIT:
         return initialState;

      case ACTION_START:
         return genNext(initGame(action.gameSelection, action.countries));

      case ACTION_GENNEXT:
         return genNext(state);

      case ACTION_CHOICE:
         const selCountry = action.selCountry;
         const idx = state.remainingCountries.indexOf(selCountry);
         const newState = { ...state };
         if (selCountry.c === state.currentCountry.c) {
            newState.numAccepted = state.numAccepted + 1;
            if (newState.numAccepted < state.numTotal) {
               newState.remainingCountries.splice(idx, 1);
               newState.lastError = null;
               return genNext(newState);
            } else {
               newState.gameDuration = new Date() - newState.startTime;
               newState.score = calcScore(newState.numErrors, newState.numTotal, newState.gameDuration);
               return newState;
            }
         } else {
            newState.numErrors++;
            newState.lastError = state.currentCountry;
            setTimeout(action.onClearError, 2000);
            return newState;
         }

      case ACTION_CLEARMISTAKE:
         const newState2 = { ...state };
         newState2.lastError = null;
         if (state.livesTotal !== null) {
            newState2.livesCurrent--;
            if (newState2.livesCurrent <= 0) {
               newState2.gameDuration = new Date() - newState2.startTime;
               newState2.score = calcScore(newState2.numTotal - newState2.numAccepted, newState2.numTotal, newState2.gameDuration);
               newState2.endMessage = 'You run out of lives!';
               return newState2;
            }
         }
         return genNext(newState2);

      default:
         console.log("Reducer - invalid action!");
         return { ...state };
   }
};


export default function GameContextProvider(props) {
   const [state, dispatch] = useReducer(gameReducer, initialState);

   return (
      <GameContext.Provider value={[state, dispatch]}>
         {props.children}
      </GameContext.Provider>
   )
}
