import React, { useEffect, useContext, useState } from 'react'
import StatusContext from '../contexts/StatusContext';
import { CountriesContext } from '../contexts/CountriesContext';
import { useLocation } from 'react-router-dom';
import { loadGameSelection } from '../logic/storage';
import { Button, Grid, Stack, Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ScoreView from './ScoreView';
import GameStatusBar from './GameStatusBar';
import { ACTION_CHOICE, ACTION_CLEARMISTAKE, ACTION_START, GameContext } from '../contexts/GameContext';

export default function GuessTheCountry() {
   const location = useLocation();

   const { setCurrentTitle } = useContext(StatusContext);
   const [gameSelection] = useState(
      location.state ? location.state : loadGameSelection()
   );

   const countries = useContext(CountriesContext);
   const [gameState, dispatch] = useContext(GameContext);

   useEffect(() => {
      setCurrentTitle({ title: "Guess the country", backBtnVisible: true });

      if (countries) {
         dispatch({ type: ACTION_START, gameSelection: gameSelection, countries: countries });
      }
      return () => {
      }
   }, [countries, gameSelection, dispatch, setCurrentTitle]);

   const handleCountryBtnClick = (selCountry) => {
      dispatch({ type: ACTION_CHOICE, selCountry: selCountry, onClearError: clearMistakeAndContinue });
   }

   const handlePlayAgainBtn = () => {
      dispatch({ type: ACTION_START, gameSelection: gameSelection, countries: countries });
   }

   const clearMistakeAndContinue = () => {
      dispatch({ type: ACTION_CLEARMISTAKE });
   }

   return (
      <>
         {countries ?
            <div style={{ height: "100%" }}>
               {gameState.score === null ?
                  <>
                     <GameStatusBar
                        numAccepted={gameState.numAccepted}
                        numErrors={gameState.numErrors}
                        numTotal={gameState.numTotal}
                        livesTotal={gameState.livesTotal}
                        livesCurrent={gameState.livesCurrent}
                        sx={{ mb: 3 }}
                     />

                     <Stack direction="column" justifyContent="space-between" alignItems='center' sx={{ height: "95%" }}>
                        <img src={`/data/${gameState.currentCountry.c}.svg`} className="flagImage" alt="flag" />

                        <Grid container spacing={1} alignItems="stretch" justifyContent="space-between">
                           {gameState.lastError &&
                              <>
                                 <Grid item xs={12} sm={1} alignSelf='center'>
                                    <WarningAmberIcon fontSize='large' color='error' />
                                 </Grid>
                                 <Grid item xs={12} sm={11} alignSelf='center'>
                                    <Typography variant='h3'>Sorry, it was {gameState.lastError.n}!</Typography>
                                 </Grid>
                              </>
                           }

                           {gameState.currentOptions.map((value, index) => {
                              return (
                                 value &&
                                 <Grid item key={index} xs={6} sm={4}>
                                    <Button fullWidth
                                       variant='contained'
                                       disabled={gameState.lastError !== null}
                                       onClick={() => handleCountryBtnClick(value)}
                                       sx={{ height: "100%" }}
                                    >
                                       {value.n}
                                    </Button>
                                 </Grid>
                              )
                           })}

                        </Grid>
                     </Stack>
                  </> :
                  <ScoreView message={gameState.endMessage} score={gameState.score} duration={gameState.gameDuration} gameSelection={gameSelection} onPlayAgain={handlePlayAgainBtn} />
               }
            </div>
            : <div>Loading ...</div>
         }
      </>
   )
}
