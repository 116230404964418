import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import MainView from './components/MainView';
import NoMatch from './components/NoMatch';
import AboutView from './components/AboutView';
import SettingsView from './components/SettingsView';
import GuessTheCountry from './components/GuessTheCountry';
import GuessTheFlag from './components/GuessTheFlag';

function App() {
   return (
      <Routes>
         <Route path='/' element={<Layout />}>
            <Route index element={<MainView />} />
            <Route path='settings' element={<SettingsView />} />
            <Route path='about' element={<AboutView />} />
            <Route path='GuessTheCountry' element={<GuessTheCountry />} />
            <Route path='GuessTheFlag' element={<GuessTheFlag />} />
            <Route path='*' element={<NoMatch />} />
         </Route>
      </Routes>
   );
}

export default App;