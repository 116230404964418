import React from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon, MenuItem, ListItemText } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function MenuItemLink(props) {
   const { icon, primary, to } = props;

   const renderLink = React.useMemo(
      () =>
         React.forwardRef(function Link(itemProps, ref) {
            return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} replace={true} />;
         }),
      [to],
   );

   return (
      <MenuItem component={renderLink}>
         {icon ? <ListItemIcon sx={{ minWidth: 33 }}>{icon}</ListItemIcon> : null}
         <ListItemText primary={primary} />
      </MenuItem>
   );
}

MenuItemLink.propTypes = {
   icon: PropTypes.element,
   primary: PropTypes.string.isRequired,
   to: PropTypes.string.isRequired,
};

export default MenuItemLink;