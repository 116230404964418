import React from 'react';

// Creating the context object and passing the default values.
const StatusContext = React.createContext(
   {
      currentTitle: {
         title: "World of flags",
         backBtnVisible: true
      },
      setCurrentTitle: () => {}
   });

export default StatusContext;