import React from 'react'
import { Grid, Typography } from '@mui/material';
import Stars from './Stars';

export default function GameStatusBar(props) {
   const { numAccepted, numErrors, numTotal, livesTotal, livesCurrent, sx } = props;
   return (
      <Grid container direction="row" justifyContent="space-between" sx={sx}>
         <Grid item sm={2}>
            <Typography variant='h3'>Mistakes: {numErrors}</Typography>
         </Grid>
         <Grid item>
            {(livesTotal !== null) &&
               <Stars total={livesTotal} current={livesCurrent} />
            }
         </Grid>
         <Grid item sm={2}>
            <Typography variant='h3' align='right'>{numAccepted} / {numTotal}</Typography>
         </Grid>
      </Grid>
   )
}
