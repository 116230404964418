import React, { useContext } from 'react'
import { Toolbar, Typography, IconButton, Tooltip, Menu, MenuItem, Divider, ListItemIcon } from '@mui/material';
import StatusContext from '../contexts/StatusContext';
import FaceIcon from '@mui/icons-material/Face';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuItemLink from './MenuItemLink';
import NavigateBackButton from './NavigateBackButton';

export default function Header() {
   const status = useContext(StatusContext);
   const [anchorEl, setAnchorEl] = React.useState(null);

   const open = Boolean(anchorEl);
   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   return (
      <React.Fragment>
         <Toolbar>
            <NavigateBackButton visible={status.currentTitle.backBtnVisible} />
            <Typography variant='h2' noWrap component='div' sx={{ flexGrow: 1 }}>
               {status.currentTitle.title}
            </Typography>

            <Tooltip title="Account settings">
               <IconButton
                  onClick={handleClick}
                  color="inherit"
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
               >
                  <FaceIcon />
               </IconButton>
            </Tooltip>
         </Toolbar>
         <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
               elevation: 0,
               sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                     width: 32,
                     height: 32,
                     ml: -0.5,
                     mr: 1,
                  },
                  '&:before': {
                     content: '""',
                     display: 'block',
                     position: 'absolute',
                     top: 0,
                     right: 14,
                     width: 10,
                     height: 10,
                     bgcolor: 'background.paper',
                     transform: 'translateY(-50%) rotate(45deg)',
                     zIndex: 0,
                  },
               },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
         >
            <MenuItem disabled>
               <ListItemIcon>
                  <FaceIcon fontSize="small" />
               </ListItemIcon>
               Profile
            </MenuItem>
            <MenuItem disabled>
               <ListItemIcon>
                  <FaceIcon fontSize="small" />
               </ListItemIcon>
               My account
            </MenuItem>
            <Divider />
            <MenuItemLink to='/settings' primary='Settings' icon={<Settings />} />
            <MenuItemLink to='/about' primary='About' icon={<InfoOutlinedIcon />} />
            <MenuItem disabled>
               <ListItemIcon>
                  <Logout fontSize="small" />
               </ListItemIcon>
               Logout
            </MenuItem>
         </Menu>
      </React.Fragment>
   );
}
